import { ReactNode } from 'react';
import Button from 'components/digifi-wrappers/Button/Button';
import styles from './BaseIntegrationElement.module.scss';

interface IBaseIntegrationElementProps {
  title: string;
  description: string;
  logo: ReactNode;
  buttonTitle?: ReactNode;
  buttonTooltip?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onButtonClick?: () => void;
}

const BaseIntegrationElement = ({
  title,
  description,
  logo,
  buttonTitle,
  buttonTooltip,
  disabled,
  isLoading,
  onButtonClick,
}: IBaseIntegrationElementProps) => {
  const renderButton = () => {
    if (!buttonTitle || !onButtonClick) {
      return null;
    }

    return (
      <Button
        onClick={onButtonClick}
        disabled={disabled}
        isLoading={isLoading}
        kind='secondary'
        className={styles.button}
        tooltip={buttonTooltip}
      >
        {buttonTitle}
      </Button>
    );
  };

  return (
    <div className={styles.container}>
      {logo}
      <div className={styles.separator}/>
      <div className={styles.content}>
        <p className={styles.title}>
          {title}
        </p>
        <p className={styles.description}>
          {description}
        </p>
      </div>
      {renderButton()}
    </div>
  );
};

export default BaseIntegrationElement;
