import { Dictionary } from '@reduxjs/toolkit';
import { PortalPageElementType } from 'api/digifi/portal-page-elements';
import { IPortalPageElement } from 'api/digifi/PortalPageElementsApi';

const findPortalPageElementByType = <Type extends IPortalPageElement>(
  elements: Dictionary<IPortalPageElement>,
  elementType: PortalPageElementType,
): Type | null => {
  return (Object.values(elements).find((element) => element?.elementType === elementType) || null) as Type | null;
};

export default findPortalPageElementByType;
