import React, { ChangeEvent, FC, FocusEvent, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import TextInput from 'product_modules/components/TextInput';
import { VariableValue } from 'product_modules/api/Types';
import { VisualDataType } from 'product_modules/enums/VisualDataType';
import { VariableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import { LoaderState } from 'product_modules/components/LoaderWithState';
import convertInputValueByVisualDataType from 'product_modules/utils/convertInputValueByVisualDataType';
import useValueCompatibilityCheck from 'product_modules/components/InputWithDataType/hooks/useValueCompatibilityCheck';
import { WarningNotificationIcon } from 'product_modules/static/images';
import WrapperWithTooltip from 'product_modules/components/Tooltip';
import styles from './IncompatibleValueInput.module.scss';

interface IncompatibleValueInputProps {
  value: string;
  labelTitle?: string;
  visualDataType: VisualDataType;
  visualAttributes: VariableVisualAttributes;
  titleHint?: string;
  onChange: (value: VariableValue) => void;
  readOnly?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  hasLeftNeighbour?: boolean;
  hasRightNeighbour?: boolean;
  placeholder?: string;
  containerClassName?: string;
  inputIcon?: React.ReactNode;
  disableValueCorrespondenceValidation?: boolean;
}

const IncompatibleValueInput: FC<IncompatibleValueInputProps> = ({
  onChange,
  visualDataType,
  visualAttributes,
  disableValueCorrespondenceValidation,
  ...inputProps
}) => {
  const isValueCompatibleWithVisualType = useValueCompatibilityCheck(visualDataType);

  const handleChange = useCallback((userValue: string) => {
    const valueCompatible = !userValue || isValueCompatibleWithVisualType(userValue, visualAttributes);
    const valueToSet = valueCompatible
      ? convertInputValueByVisualDataType(userValue, visualDataType, visualAttributes)
      : userValue;

    onChange(valueToSet);
  }, [visualDataType, visualAttributes]);

  const inputPrefix = useMemo(() => {
    if (disableValueCorrespondenceValidation) {
      return null;
    }

    return (
      <WrapperWithTooltip tooltip="The value is not compatible with this variable's type">
        <div className={clsx(styles.warningIconContainer)}>
          <WarningNotificationIcon />
        </div>
      </WrapperWithTooltip>
    );
  }, [disableValueCorrespondenceValidation]);

  return (
    <div className={styles.container}>
      <TextInput
        {...inputProps}
        className={clsx(inputPrefix && styles.inputWithWarningIcon)}
        inputPrefix={inputPrefix}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => handleChange(target.value)}
      />
    </div>
  );
};

export default IncompatibleValueInput;
