import { useAppDispatch } from 'hooks/reduxHooks';
import { ILegalDocument } from 'api/digifi/SettingsApi';
import { setSelectedDocument } from 'handlers/settingsSlice';
import LegalConsentsCheckbox from './LegalConsentsCheckbox';

interface ILegalConsentsProps {
  className?: string;
  isChecked: boolean;
  onCheck: () => void;
  legalDocuments: ILegalDocument[];
}

const LegalConsents = (props: ILegalConsentsProps) => {
  const dispatch = useAppDispatch();

  const handleLegalDocumentClick = (legalDocument: ILegalDocument) => {
    dispatch(setSelectedDocument(legalDocument));
  };

  return (
    <LegalConsentsCheckbox
      onLegalDocumentClick={handleLegalDocumentClick}
      {...props}
    />
  );
};

export default LegalConsents;
