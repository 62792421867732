import BaseApi, { FetchMethodType } from 'api/BaseApi';
import {
  IHeaderTextPageElement,
  ISubHeaderTextPageElement,
  IBodyTextPageElement,
  IPageDividerElementConfig,
  IPageDividerPageElement,
  ITasksElementConfig,
  ITasksPageElement,
  IOfferSelectionElementConfig,
  IOfferSelectionPageElement,
  IImageElementConfig,
  IImagePageElement,
  IButtonElementConfig,
  IButtonPageElement,
  ITextElementConfig,
  IDataInputPageElement,
  IDataInputElementConfig,
  IProgressBarPageElement,
  IProgressBarElementConfig,
  IESignatureElement,
  IESignatureElementConfig,
  IPlaidLinkPageElement,
  IPlaidLinkElementConfig,
} from './portal-page-elements';

export type ITextPageElement =
  | IHeaderTextPageElement
  | ISubHeaderTextPageElement
  | IBodyTextPageElement;

export type IPortalPageElement =
  | ITextPageElement
  | IPageDividerPageElement
  | ITasksPageElement
  | IOfferSelectionPageElement
  | IImagePageElement
  | IButtonPageElement
  | IDataInputPageElement
  | IProgressBarPageElement
  | IESignatureElement
  | IPlaidLinkPageElement;

export type IPageElementConfig =
  | ITextElementConfig
  | IPageDividerElementConfig
  | ITasksElementConfig
  | IOfferSelectionElementConfig
  | IImageElementConfig
  | IButtonElementConfig
  | IDataInputElementConfig
  | IProgressBarElementConfig
  | IESignatureElementConfig
  | IPlaidLinkElementConfig;

export interface IFindPortalPageElementsParams {
  statusId: string;
}

export interface IGenerateImageUrlsParams {
  imageElementIds: string[];
}

export interface IPortalPageElementsApi {
  find(params: IFindPortalPageElementsParams): Promise<IPortalPageElement[]>;
  generateImageUrls(params: IGenerateImageUrlsParams): Promise<Record<string, string>>;
}

export default class PortalPageElementsRestApi extends BaseApi implements IPortalPageElementsApi {
  public find(params: IFindPortalPageElementsParams): Promise<IPortalPageElement[]> {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.set('statusId', params.statusId);

    return this.fetch(`/portal-page-elements?${urlSearchParams}`);
  }

  public generateImageUrls(params: IGenerateImageUrlsParams) {
    return this.fetch<Record<string, string>>('/portal-page-elements/urls', {
      method: FetchMethodType.POST,
      body: {
        imageElementIds: params.imageElementIds,
      },
    });
  }
}
