import clsx from 'clsx';
import { ChangeEvent, FC, useRef, useState } from 'react';
import EmailInput from 'components/digifi-wrappers/EmailInput';
import PhoneNumberInput from 'components/digifi-wrappers/PhoneNumberInput';
import { useAppSelector } from 'hooks/reduxHooks';
import { useFormik } from 'formik';
import { validateEmail, validatePasswordByRules, validatePhoneNumber } from 'utils/validation';
import getMessage, { MessageType } from 'constants/Messages';
import { ICreateAccountFormParams } from 'types';
import TriggerEventOnEnterKeyDown from 'product_modules/utils/TriggerEventOnEnterKeyDown';
import { CountryCode } from 'libphonenumber-js';
import Button from 'components/digifi-wrappers/Button';
import { LeftArrowImage } from 'product_modules/static/images';
import FormFooterLink from 'components/PageLayout/FormFooterLink';
import { AppRoute } from 'enums/AppRoute';
import LegalConsents from 'components/common/LegalConsents';
import styles from './CreateAccountForm.module.scss';
import PasswordInputWithHint from 'product_modules/components/PasswordInputWithHint';

interface ICreateAccountInitialData {
  email?: string;
  phoneNumber?: string,
}

interface ICreateAccountFormProps {
  handleSubmit: (params: ICreateAccountFormParams) => Promise<void>;
  isLoading?: boolean;
  initialData: ICreateAccountInitialData;
  allowEditInitialData?: boolean;
  submitButtonClassName?: string;
  onBack?: () => void;
  useLegalConsents?: boolean;
}

const CreateAccountForm: FC<ICreateAccountFormProps> = ({
  handleSubmit,
  isLoading,
  initialData,
  allowEditInitialData,
  submitButtonClassName,
  onBack,
  useLegalConsents = true,
}) => {
  const { legalDocuments } = useAppSelector(state => state.settings);
  const createApplicationLegalDocuments = legalDocuments.filter(document =>
    document.showOnSubmitApplication);
  const { phoneNumberFormat } = useAppSelector((state) => state.settings.variablesSettings);
  const [isLegalConsentChecked, setIsLegalConsentChecked] = useState<
    boolean
  >(!useLegalConsents || !createApplicationLegalDocuments.length);

  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const { values, errors, setFieldValue, setFieldError } = useFormik({
    initialValues: {
      email: initialData?.email || '',
      phoneNumber: initialData?.phoneNumber || '',
      password: '',
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    enableReinitialize: true,
  });
  
  const [passwordErrors, setPasswordErrors] = useState<MessageType[]>(validatePasswordByRules(values.password));

  const clearFieldErrorOnFocus = (fieldName: string) => {
    setFieldError(fieldName, '');
  };

  const handleEmailBlur = (currentEmail: string) => {
    const trimmedEmail = currentEmail.trim();

    const error = validateEmail(trimmedEmail);
    setFieldValue('email', trimmedEmail);
    setFieldError('email', error === null ? '' : getMessage(error));
  };


  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    const error = validatePasswordByRules(passwordValue);
    
    setFieldValue('password', passwordValue);
    setPasswordErrors(error);
  };

  const handlePhoneBlur = (currentPhone: string) => {
    const error = validatePhoneNumber(currentPhone);
    setFieldError('phoneNumber', error === null ? '' : getMessage(error));
  };

  const renderBackButton = () => (
    <Button className={styles.backButton} kind="secondary" size="form" onClick={onBack}>
      <LeftArrowImage />
      Back
    </Button>
  );

  const renderLegalConsents = () => {
    if (!useLegalConsents || !createApplicationLegalDocuments.length) {
      return null;
    }

    return (
      <LegalConsents
        className={styles.legalConsents}
        isChecked={isLegalConsentChecked}
        onCheck={() => setIsLegalConsentChecked(!isLegalConsentChecked)}
        legalDocuments={createApplicationLegalDocuments}
      />
    );
  };

  const isButtonEnabled = !validatePhoneNumber(values.phoneNumber)
    && !validateEmail(values.email)
    && !validatePasswordByRules(values.password).length
    && isLegalConsentChecked;

  return (
    <div>
      <EmailInput
        labelTitle="Email"
        onChange={(value) => setFieldValue('email', value)}
        onFocus={() => clearFieldErrorOnFocus('email')}
        onBlur={() => handleEmailBlur(values.email)}
        value={values.email || ''}
        errorMessage={errors.email || ''}
        name="email"
        disabled={isLoading || (!allowEditInitialData && !!initialData.email)}
        required
        ref={emailInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(phoneNumberInputRef, 'focus')}
        autoComplete="username email"
      />
      <PhoneNumberInput
        withFlag
        labelTitle="Phone"
        value={values.phoneNumber}
        onChange={(value) => setFieldValue('phoneNumber', value)}
        onFocus={() => clearFieldErrorOnFocus('phoneNumber')}
        onBlur={() => handlePhoneBlur(values.phoneNumber)}
        name="phoneNumber"
        required
        country={phoneNumberFormat as CountryCode}
        errorMessage={errors.phoneNumber}
        disabled={isLoading}
        ref={phoneNumberInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordInputRef, 'focus')}
        autoComplete="off"
      />
      <PasswordInputWithHint
        name="password"
        labelTitle="Password"
        required
        value={values.password}
        onChange={handlePasswordChange}
        disabled={isLoading}
        ref={passwordInputRef}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        autoComplete="new-password"
        invalidMessageTypes={passwordErrors}
      />
      {renderLegalConsents()}
      <div className={styles.buttonsContainer}>
        {onBack && renderBackButton()}
        <Button
          size="form"
          kind="primary"
          disabled={!isButtonEnabled}
          onClick={() => handleSubmit(values)}
          className={clsx(styles.submitButton, submitButtonClassName)}
          ref={submitButtonRef}
          isLoading={isLoading}
        >
          Create Account
        </Button>
      </div>
      <FormFooterLink
        text='Already have an account?'
        linkText='Sign in'
        to={AppRoute.SignIn}
        className={styles.footerLink}
      />
    </div>
  );
};

export default CreateAccountForm;
