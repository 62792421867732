import {
  IBasePortalPageElement,
  PortalPageElementType,
} from './BasePortalPageElement';
import { PlaidIncomeSourceType, PlaidProduct } from 'api/digifi/PlaidApi';

export enum PlaidLinkCategory {
  FinancialData = 'financial_data',
  IncomeVerification = 'income_verification',
}

export interface IPlaidLinkElementConfig {
  linkCustomizationName?: string;
  category: PlaidLinkCategory;
  products: PlaidProduct[];
  tokenVariable: string;
  incomeSource?: PlaidIncomeSourceType;
  daysRequested?: string;
  additionalProducts?: PlaidProduct[];
  additionalTokenVariable?: string;
}

export interface IPlaidLinkPageElement extends IBasePortalPageElement {
  elementType: PortalPageElementType.PlaidLink;
  config: IPlaidLinkElementConfig;
}
